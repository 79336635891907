@import "styles/responsive";

packex-change-password {
  display: flex;
  justify-content: center;

  packex-card {
    margin-top: 5rem;
  }

  @include media-breakpoint-down(md) {
    align-items: flex-start;

    packex-card {
      margin-top: 1rem;
      box-shadow: none;
    }
  }
}

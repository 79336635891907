@import "styles/responsive";

packex-card {
  @include media-breakpoint-down(md) {
    box-shadow: none !important;
    width: 100% !important;
    padding: 0 !important;
    background-color: transparent !important;
  }
}

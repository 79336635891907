@import "styles/responsive";

packex-unsubscribe-notification {
  display: flex;
  justify-content: center;

  packex-empty-screen {
    margin-top: 5rem !important;
  }

  @include media-breakpoint-down(md) {
    align-items: flex-start;

    packex-empty-screen {
      margin-top: 1rem !important;
    }
  }
}

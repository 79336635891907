.packex-select {
  .mat-mdc-text-field-wrapper {
    align-items: center;
  }
  .mdc-text-field {
    padding: 0;
  }

  .mdc-floating-label {
    font-weight: 600;
    font-size: 1.063rem;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 50% !important;
  }

  .mdc-text-field--filled .mdc-floating-label--float-above {
    display: none;
  }

  &.mat-mdc-form-field {
    display: flex;
    border-radius: 4px;
    border: solid 1px var(--primary-dark);
    height: 2.25rem;
    align-items: center;
    padding: 0 1rem;
    //min-width: 12rem;
    &.mat-form-field-disabled {
      border: solid 1px var(--grey-medium);

      mat-label {
        color: var(--grey-dark) !important;
      }

      .mat-mdc-select-arrow {
        color: var(--grey-dark) !important;
      }
    }

    &:not(.mat-form-field-disabled):hover {
      border: 1px solid var(--secondary-medium);
    }
  }

  .mat-mdc-form-field-infix {
    display: flex;
    position: relative;
    flex: auto;
    min-width: 100%;
    align-items: center;
    height: 100%;
    min-height: auto;
    border: none;
    padding: 0 !important;
  }

  .mat-mdc-select-value-text {
    color: var(--primary-dark);
    font-weight: 600;
    font-size: 1.063rem;
  }

  .mat-mdc-form-field-label {
    color: var(--primary);
  }

  .mat-mdc-form-field-label-wrapper {
    top: -0.6rem;
  }

  .mat-mdc-form-field-wrapper {
    width: 100%;
    padding: 0;
    display: flex;
  }

  &.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
    padding: 0;
    background-color: transparent;
    min-height: 1.1rem;
  }

  &.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    transform: none !important;

    .mat-mdc-select-arrow {
      color: var(--primary-dark)
    }
  }
}

.mat-mdc-option {
  &.hide {
    display: none;
  }
  &:hover:not(.mdc-list-item--disabled) {
    background-color: var(--secondary-light) !important;
  }
}



.mat-mdc-option {
  &.d-flex {
    > span {
      display: flex;
    }
  }
}

@import "styles/responsive";

packex-user-accept-invitation {
  display: flex;
  justify-content: center;

  packex-card, packex-empty-screen {
    margin-top: 5rem !important;
    margin-bottom: 3rem;
  }

  @include media-breakpoint-down(md) {
    align-items: flex-start;

    packex-card {
      margin-top: 1rem !important;
      box-shadow: none !important;
      width: 100% !important;
      background-color: transparent !important;
    }
  }
}
